import React, { useEffect } from 'react';
import useSound from 'use-sound'

import logo from './transparent.png';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Alert, Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';

const errorSound = require('./sounds/Soft Fail.mp3')
const okSound = require('./sounds/Win Game Coin.mp3')

type LoadingProps = {
  doneLoading: () => void;
}

const Loading = ({ doneLoading }: LoadingProps) => {
  const logoRef = React.useRef<HTMLImageElement>(null);

  useEffect(() => {
    const logo = logoRef.current;
    if (logo) {
      logo.style.opacity = '1';
      logo.style.transition = 'opacity 1s, width 1s';
      setTimeout(() => {
        logo.style.opacity = '0';
        logo.style.width = '300px';
        setTimeout(() => {
          logo.style.display = 'none';
          doneLoading();
        }, 1000);
      }, 100);
    }
  })

  return (
    <div id='loading'>
      <img ref={logoRef} src={logo} alt='prefiQuest' />
    </div>
  );
}

const questions = [
  {
    text: 'What is the capital of France?',
    type: 'multiple',
    options: {
      "a": "Paris",
      "b": "London",
      "c": "Berlin",
      "d": "Madrid"
    },
    correct: "a"
  },
  {
    text: 'What is the capital of Germany?',
    type: 'multiple',
    options: {
      "a": "Paris",
      "b": "London",
      "c": "Berlin",
      "d": "Madrid"
    },
    correct: "c"
  },
  {
    text: 'What is the capital of Spain?',
    type: 'multiple',
    options: {
      "a": "Paris",
      "b": "London",
      "c": "Berlin",
      "d": "Madrid"
    },
    correct: "d"
  },
  {
    text: 'What is the capital of the United Kingdom?',
    type: 'multiple',
    options: {
      "a": "Paris",
      "b": "London",
      "c": "Berlin",
      "d": "Madrid"
    },
    correct: "b"
  },
  {
    text: 'What is the capital of Portugal?',
    type: 'multiple',
    options: {
      "a": "Paris",
      "b": "London",
      "c": "Berlin",
      "d": "Lisbon"
    },
    correct: "d"
  },
  {
    text: 'What is the capital of Italy?',
    type: 'multiple',
    options: {
      "a": "Paris",
      "b": "London",
      "c": "Rome",
      "d": "Madrid"
    },
    correct: "c"
  },
  {
    text: 'What is the capital of the Netherlands?',
    type: 'multiple',
    options: {
      "a": "Paris",
      "b": "London",
      "c": "Berlin",
      "d": "Amsterdam"
    },
    correct: "d"
  },
  {
    text: 'What is the capital of Belgium?',
    type: 'multiple',
    options: {
      "a": "Paris",
      "b": "Brussels",
      "c": "Berlin",
      "d": "Madrid"
    },
    correct: "b"
  }
]


const Question = () => {
  const [playError] = useSound(errorSound)
  const [playOk] = useSound(okSound)

  const [answer, setAnswer] = React.useState<string | null>(null);
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [question, setQuestion] = React.useState(questions[0]);

  const saveAnswer = (opt: string) => {
    if (answer) return

    setAnswer(opt)
    if (question.correct === opt) {
      playOk()
    } else {
      playError()
    }
  }

  const nextQuestion = () => {
    setAnswer(null)
    if(questionIndex < questions.length - 1) {
      setQuestionIndex(questionIndex + 1)
      setQuestion(questions[questionIndex + 1])
    }
  }

  return (
    <section className='question'>
      <header>
        <ProgressBar now={questionIndex/questions.length * 100} />
        <br />
        <h4>{question.text}</h4>
      </header>
      <div className="content">
        {question.type === 'multiple' && <>
          {Object.keys(question.options).map((key, idx) => {
            return (

              <Button
                key={idx}
                size="lg"
                className='answer'
                onClick={() => saveAnswer(key)}

                variant={
                  answer === key ? (answer === question.correct ? "success" : "outline-warning") : "outline-info"
                }>{question.options[key as keyof typeof question.options]}</Button>
            )
          })}
        </>}

        {question.type === 'input' && <input type='text' />}
      </div>
      <footer>
        {answer && answer !== question.correct && <Alert variant='danger'>
          The correct answer is: {question.options[question.correct as keyof typeof question.options]}
        </Alert>}
        {answer && answer === question.correct && <Alert variant='success'>
          Correct!
        </Alert>}
        <Button
          disabled={!answer}
          size='lg'
          variant={!answer ? "outline-secondary" : (answer === question.correct ? "success" : "outline-danger")}
          onClick={nextQuestion}
        >Continue</Button>
      </footer>
    </section>
  );
}


function App() {
  const [loaded, setLoaded] = React.useState(false);

  return (
    <>
      {!loaded && <Loading doneLoading={() => { setLoaded(true) }} />}
      {loaded && <main>

        <Question />
      </main>}
    </>
  );
}

export default App;
